import React, { Component, Text } from "react";
import { Table } from "reactstrap";

class SensorList extends Component {

    logData(){
        const sensors = this.props.sensors;
        console.log(sensors);
    }

    render() {
        const sensors = this.props.sensors;
        this.logData();

        const trclickf = (row) =>
        {
            console.log(row.id)
        }

        return (
            <Table dark>
            <thead>
                <tr>
                <th>Serial Number</th>
                <th>Last Modified Date</th>
                <th>Bootloader</th>
                <th>Collimation</th>
                <th>Diffuser</th>
                <th>Calibration</th>
                <th>Pan Tilt</th>
                </tr>
            </thead>
            <tbody>
                {!sensors || sensors.length <= 0 ? (
                <tr>
                    <td colSpan="7" align="center">
                    <b>No sensors available</b>
                    </td>
                </tr>
                ) : (
                sensors.map(sensor => (
                    <tr key={sensor.serial_number}>
                    <td>{sensor.serial_number}</td>
                    <td>{sensor.last_modified_date}</td>
                    <td id='howdy'></td>
                    <td></td>
                    <td></td>
                    <td>{sensor.calibrations[sensor.calibrations.length-1].pass_fail ? 
                    <span class="badge text-bg-success">PASS</span> : 
                    <span class="badge text-bg-danger">FAIL</span>}</td>
                    <td></td>
                    </tr>
                ))
                )}
            </tbody>
            </Table>
        );
    }
}

export default SensorList;