export const BASE_API_URL = "https://production.seensafety.com/records/api";
export const SENSOR_LIST = "";
export const CAL_LIST = "/cal";
export const BL_LIST = "/bl";
export const SENSOR_URL = "/"; //then the ID
export const CAL_URL = "/cal/" //then the id


export const API_URL = "https://production.seensafety.com/records/api?sort_by=-last_modified_date"; // ?limit=50&offset=200 for pagination...

export const BASE_MEDIA_URL = "https://production.seensafety.com/media/"; // TODO we need auth too....
export const BASE_URL = "https://production.seensafety.com";
//SENSOR_URL is just the serial number after api/<sensorid>
//CAL_URL is /cal/<calid>