import { Form, useLoaderData, Link, useLocation } from "react-router-dom";
import { Table, Label, Input, Dropdown, Form as RsForm, DropdownMenu, UncontrolledDropdown, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink, FormGroup } from "reactstrap";
import axios from "axios";



import { BASE_API_URL } from "../constants";

function constructAPIURL(searchParams){
    var api_url = BASE_API_URL;
    // Append the search params to the base url for the sensor list
    var count = 0;
    searchParams.forEach((value, key) => {
        if(count===0){
            api_url += "?";
        }
        else{
            api_url += "&";
        }
        count++;
        api_url += key + "=" + value;
    });
    return api_url;
}

function constructAPICountURL(failed){
    var api_url = BASE_API_URL;
    api_url += "?count=y";
    if(failed){
        api_url += '&failed=p'
    }
    return api_url;
}

  export async function getSensors(request){
    const url = new URL(request.url);
    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(searchTerm);
    const count_url = constructAPICountURL(searchTerm.has('failed'));
    const count =  await axios.get(count_url);
    const c3 = await axios.get(api_url);
    return {  count,  c3 };
  }

  export async function loader({ request }){
    console.log(request.url)
    const {  count,  c3} = await getSensors(request);

    const url = new URL(request.url);
    var baseUrl = url.origin + url.pathname;
    var param_count = 0;
    url.searchParams.forEach((value, key) => {
        if(! (key==="limit" || key==="offset")){
        if(param_count===0){
            baseUrl += "?";
        }
        else{
            baseUrl += "&";
        }
        param_count++;
        baseUrl += key + "=" + value;
    }});
    if(!baseUrl.includes("?")){
        baseUrl+="?"; // Append the ? so our pagination is ok...
    }



    const searchTerm = url.searchParams;
    console.log(searchTerm);
    var failedList = searchTerm.has('failed');
    const limit = searchTerm.get('limit');
    const offset = searchTerm.get('offset');
    return {  count,  c3, limit, offset, baseUrl, failedList };
  }

export function SensorList() {
  const {  count,  c3, limit, offset, baseUrl, failedList } = useLoaderData();
  const sensor_count = count.data['count'];
  const sensors = c3.data;

  const curLimit = 100; //change this at some point in dd or similar...
  var start, end;
  if(offset!=null){
    start = parseInt(offset);
  }
  else{
    start = 0;
  }
  if(limit!=null){
    end = parseInt(limit) + parseInt(offset);
    if(end > parseInt(sensor_count)) { end = parseInt(sensor_count); }
  }
  else{
    end = curLimit;
  }
  var page = 1 + parseInt(start / parseInt(curLimit));
  var pagelist = []
  for(let i=1; i <= 1+(sensor_count/curLimit); i++){
    pagelist.push(i);
  }
  

  return (
    <>
    <div className="py-3" style={{display:"flex", flexDirection:"row", color:"#FFFFFF", backgroundColor:"#222222"}}>
        <h2 className="px-2">Sensor List</h2>
        <div style={{display:"flex", flexDirection:"row"}}>
            <h4 className="px-5">Displaying <i>{ start }</i> to <i>{ end }</i> of <i>{ sensor_count }</i> sensors</h4>
            <div className="px-5">
            <Pagination size="me">
                <PaginationItem>
                <PaginationLink first href={baseUrl + "&limit=" + curLimit + "&offset=0" } />
                </PaginationItem>
                <PaginationItem>
                <PaginationLink previous href={baseUrl + "&limit=" + curLimit + "&offset=" + (page-2)*curLimit}/>
                </PaginationItem>
               
                <PaginationItem>
                    <UncontrolledDropdown>
                        <DropdownToggle caret>
                            { page }
                        </DropdownToggle>
                        <DropdownMenu style={{height: 300, overflow: "auto" }}>
                                {pagelist.map(item => (
                                <DropdownItem key={item} href={baseUrl + "&limit=" + curLimit + "&offset=" + (item-1)*curLimit}>{item}</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </PaginationItem>



                <PaginationItem>
                <PaginationLink next href={baseUrl + "&limit=" + curLimit + "&offset=" + (page)*curLimit}/>
                </PaginationItem>
                <PaginationItem>
                <PaginationLink last href={baseUrl + "&limit=" + curLimit + "&offset=" + curLimit * (parseInt(parseInt(sensor_count) / curLimit)) }  />
                </PaginationItem>
            </Pagination>
 
            </div>           
            <RsForm>


            <FormGroup switch>
                <Input type="switch" role="switch" checked={failedList} onChange={(e) => 
                {
                    window.location.href = 
                    e.target.checked ? 
                    'sensors?failed=p' : 'sensors'
            }}
        
                />
                <Label check>Display only units with current Failures</Label>
            </FormGroup>
            </RsForm>
            {/* <div className="d-flex px-5">
            <Dropdown>
                <DropdownToggle caret>Sensors per page</DropdownToggle>
                <DropdownMenu>
                <DropdownItem>50</DropdownItem>
                <DropdownItem>100</DropdownItem>
                <DropdownItem>200</DropdownItem>
                <DropdownItem>500</DropdownItem>
                </DropdownMenu>
            </Dropdown>
            </div> */}
        </div>
    </div>
    <Table dark striped>
    <thead>
        <tr>
        <th>Serial Number <Link to="/sensors?sort_by=-serial_number">↓</Link> <Link to="/sensors?sort_by=serial_number">↑</Link></th>
        <th>Last Modified Date <Link to="/sensors?sort_by=-last_modified_date">↓</Link> <Link to="/sensors?sort_by=last_modified_date">↑</Link></th>
        <th>Last Bootloader</th>
        <th>Last Collimation</th>
        <th>Last Diffuser</th>
        <th>Last Calibration</th>
        <th>Last Pan Tilt</th>
        </tr>
    </thead>
    <tbody>
        {!sensors || sensors.length <= 0 ? (
        <tr>
            <td colSpan="7" align="center">
            <b>No sensors available</b>
            </td>
        </tr>
        ) : (
        sensors.map(sensor => (
            <tr key={sensor.serial_number}>
            <td><Link to={"/sensors/"+sensor.serial_number}>{sensor.serial_number}</Link></td>

            <td>{sensor.last_modified_date}</td>

            <td>
                {sensor.bootloaders.length > 0 ? (
                    sensor.bootloaders[sensor.bootloaders.length-1].pass_fail ? 
                    <span className="badge text-bg-success">PASS</span> : 
                    <span className="badge text-bg-danger">FAIL</span>)
                : 
                ("")}</td>
            <td>{sensor.collimations.length > 0 ? (
                    sensor.collimations[sensor.collimations.length-1].pass_fail ? 
                    <span className="badge text-bg-success">PASS</span> : 
                    <span className="badge text-bg-danger">FAIL</span>)
                : 
                ("")}</td>
            <td>{sensor.diffusers.length > 0 ? (
                    sensor.diffusers[sensor.diffusers.length-1].pass_fail ? 
                    <span className="badge text-bg-success">PASS</span> : 
                    <span className="badge text-bg-danger">FAIL</span>)
                : 
                ("")}</td>
            <td>
                {sensor.calibrations.length > 0 ? (
                    sensor.calibrations[sensor.calibrations.length-1].pass_fail ? 
                    <span className="badge text-bg-success">PASS</span> : 
                    <span className="badge text-bg-danger">FAIL</span>)
                : 
                ("")}
            </td>
            <td>
            {sensor.pantilts.length > 0 ? (
                    sensor.pantilts[sensor.pantilts.length-1].pass_fail ? 
                    <span className="badge text-bg-success">PASS</span> : 
                    <span className="badge text-bg-danger">FAIL</span>)
                : 
                ("")}
            </td>
            </tr>
        ))
        )}
    </tbody>
    </Table>
    </>
  );
}