import React, { Component } from "react";
import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    NavbarText,
  } from 'reactstrap';


class Header extends Component {
    render () {
        return (
            <div>
      <Navbar >
        <NavbarBrand href="/"><img
               src="https://images.squarespace-cdn.com/content/v1/5f1e26cb68df2a40e2e01ba5/32b634b1-0263-4f4e-b340-63d5cf9c04de/Seen+Safety+logo+%281%29.png?format=1500w"
               width="200"
               alt="Seen Safety"
               className="img-thumbnail"
               style={{ marginTop: "10px" }}
             /></NavbarBrand>
          <Nav className="me-auto" style={{ display: "inline-block" }} navbar>
            <NavItem >
              <NavLink href="/components/">SENSORS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">
                JIGS
              </NavLink>
            </NavItem>
          </Nav>
          <NavbarText>LOGIN</NavbarText>
      </Navbar>
    </div>
        //     <nav class="navbar navbar-expand-lg navbar-light bg-light">
        //     <div class="navbar-brand">
        //     <img
        //       src="https://images.squarespace-cdn.com/content/v1/5f1e26cb68df2a40e2e01ba5/32b634b1-0263-4f4e-b340-63d5cf9c04de/Seen+Safety+logo+%281%29.png?format=1500w"
        //       width="200"
        //       className="img-thumbnail"
        //       style={{ marginTop: "20px" }}
        //     />
        //   </div>
        //   <div className="text-center">
        //     <h2>SENSORS | JIGS</h2>
        //   </div>
        //   <div className="text-right">
        //     <h3>LOGIN</h3>
        //               </div>
        //               </nav>


        );
      }
    }
    
    export default Header;