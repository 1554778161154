import { Form,  useLoaderData, Link, useLocation, Outlet, NavLink as RRNavLink, useParams, useNavigate } from "react-router-dom";
import {useRef, useState} from 'react';
import { Table, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink,     Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink, Button, ButtonGroup } from "reactstrap";
import axios from "axios";



import { BASE_API_URL, BASE_URL } from "../constants";

function constructAPIURL(coll_id){
    var api_url = BASE_API_URL;
    api_url += "/coll/";
    api_url += coll_id;
    return api_url;
}

  export async function getSensors(request, coll_id){
    const url = new URL(request.url);

    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(coll_id);
    const coll_data = await axios.get(api_url);
    return {  coll_data };
  }

  export async function loader({ request, params }){
    const { coll_data } = await getSensors(request, params.collid);
    return {  coll_data };
  }


export function CollDetail() {
    const { coll_data } = useLoaderData();
    const navigate = useNavigate();

    console.log(coll_data);

    return(
        <>
        <div style={{display:"flex", flexDirection:"row"}}>

        <div className="px-3 py-3">
            <h1><Link to={"/sensors/" + coll_data.data['sensor']}>SN #{coll_data.data['sensor']}</Link></h1>
            <h4>{coll_data.data['date']}</h4>
            { <h4>
                Result :: {coll_data.data['pass_fail'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</h4> }
             
            
            <h5>Collimation Alignment ID: {coll_data.data.id}</h5>
        </div>
        
     

    <div style={{display:"flex", flexDirection:"row", width:"50%"}}>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
        <div style={{width:"80%", display:"flex", flexDirection:"column"}}>
                       
        <img src={
        (coll_data.data['photo']) ? 
        BASE_URL+coll_data.data['photo']:
      "/no_img.png"} alt="Sensor photo" width="1280" height="960"></img>
         
        </div>

     </div>

    {/* <h1>{tableData}</h1> */}
    </div>
    </div>
    </>
    );
}