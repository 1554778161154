import { Form,  useLoaderData, Link, useLocation, Outlet, NavLink as RRNavLink, useParams, useNavigate } from "react-router-dom";
import {useRef, useState} from 'react';
import { Table, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink,     Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink, Button, ButtonGroup } from "reactstrap";
import axios from "axios";



import { BASE_API_URL } from "../constants";

function constructAPIURL(pt_id){
    var api_url = BASE_API_URL;
    api_url += "/pt/";
    api_url += pt_id;
    return api_url;
}
function constructSensorAPIURL(serial_number){
    var api_url = BASE_API_URL;
    api_url += "/";
    api_url += serial_number;
    return api_url; 
}

function constructAPICountURL(){
    var api_url = BASE_API_URL;
    api_url += "?count=y";
    return api_url;
}

const TableDataType = {
    Distance: 0,
    Ratio: 1,
}

const TableDataAverage = {
    Min: 0,
    Mean: 1,
    Max: 2,
    SDev: 3,
}
/*
Generate the value for a given chanel with a given
set of data (ft_chan or ss_chan data) and whether
we are after distance or signal
*/
function getTableEntryValue(chan, data, distanceOrSignal, MinMeanMax){
    var key_p1 = distanceOrSignal===TableDataType.Distance ? "dist_" : "ratio_";
    var key_p2 = ''
    switch(MinMeanMax){
        case TableDataAverage.Min:
            key_p2 = 'min';
            break;
        case TableDataAverage.Mean:
            key_p2 = 'mean';
            break;
        case TableDataAverage.Max:
            key_p2 = 'max';
            break;
        case TableDataAverage.SDev:
            key_p2 = 'sdev';
            break;
    }
    try{
        console.log('begin');
        //console.log(chan);
        //console.log(data);
        //console.log(key_p2);
        //console.log(key_p1+key_p2);
        //console.log(data[chan][(key_p1+key_p2)]);
        var chan_id = 999 // Different to the channel this goes from 0,1,2,...
        for (const [key,value] of Object.entries(data)){
            if(data[key]['chan'] === chan){
                console.log('MATCH');
                chan_id = key;
            }
            //console.log(key)
            //console.log(data[key]['chan']);
        }
        return data[chan_id][(key_p1+key_p2)];
    }
    catch (error){
        return "";
    }   
}

  export async function getSensors(request, pt_id){
    const url = new URL(request.url);

    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(pt_id);
    const pt_data = await axios({
        url: api_url,
        method: "get",
        withCredentials: true
    });
    //const pt_data = await axios.get(api_url);
    return {  pt_data };
  }

  export async function loader({ request, params }){
    const { pt_data } = await getSensors(request, params.ptid);
    return {  pt_data };
  }

  function renderChannelTable(jsonData){
    /*
    Renders the 24 channel tables for a given data set

    Should be called when one of the links on the side
    is clicked, then we can destroy and update the table...
    */
   return (
    <>
  
        </>
   );
  }

export function PTDetail() {
    const { pt_data } = useLoaderData();
    const navigate = useNavigate();

    console.log(pt_data);
    
    var [nav_test_type, set_nav_test_type] = useState(0);
    var [nav_average, set_nav_average] = useState(1);
    var [tableData, setTableData] = useState(pt_data.data['ss_chans'])

    return(
        <>
        <div style={{display:"flex", flexDirection:"column"}}>

        <div className="px-3 py-3">
            <h1><Link to={"/sensors/" + pt_data.data['sensor']}>SN #{pt_data.data['sensor']}</Link></h1>
            <h4>{pt_data.data['date']}</h4>
            { <h4>
                Result :: {pt_data.data['pass_fail'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</h4> }
             
            
            <h5>Pan Tilt ID: {pt_data.data.id}</h5>
        </div>
        
    <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{width:"50%"}}>
    <Table striped dark style={{width:"90%"}} className="centered">
    <thead>
        <tr>
        <th>Item</th>
        <th>Value</th>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td>Signal Strength Pass</td>
        <td>{pt_data.data['ss_pass'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</td>
    </tr>
    <tr>
        <td>False Trigger Pass</td>
        <td>{pt_data.data['ft_pass'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</td>
    </tr>
    <tr>
        <td>Channel Sweep Pass</td>
        <td>{pt_data.data['cs_pass'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</td>
    </tr>
    <tr>
        {<td title={JSON.stringify(pt_data.data['configuration'])}>
            
            
            Configuration (Hover)</td>}
        <td>---</td>
    </tr>

    </tbody>
    </Table>
    </div>

    <div style={{display:"flex", flexDirection:"row", width:"50%"}}>
        <div style={{width:"20%", display:"flex", flexDirection:"column"}}>
            <h4 style={{textAlign:"center"}}>Test</h4>
                <ButtonGroup block style={{display:"flex", flexDirection:"column"}}>
                    <Button color="info" block active={nav_test_type===0} onClick={() => {setTableData(tableData=pt_data.data['ss_chans']); set_nav_test_type(0)}} >
                        Signal Strength Distance
                    </Button>
                    <Button color="info" block active={nav_test_type===1} onClick={() => {setTableData(tableData=pt_data.data['ss_chans']); set_nav_test_type(1)}} >
                    Signal Strength Signal
                    </Button>
                    <Button color="info" block active={nav_test_type===2} onClick={() => {setTableData(tableData=pt_data.data['ft_chans']); set_nav_test_type(2)}} >
                        False Trigger Distance
                    </Button>
                    <Button color="info" block active={nav_test_type===3} onClick={() => {setTableData(tableData=pt_data.data['ft_chans']); set_nav_test_type(3)}} >
                        False Trigger Signal
                    </Button>
            </ButtonGroup>            
            <h4 style={{textAlign:"center"}}>Mean</h4>
            <ButtonGroup block style={{display:"flex", flexDirection:"column"}}>
                    <Button color="info" block active={nav_average===0} onClick={() => {set_nav_average(0)}} >
                        Min
                    </Button>
                    <Button color="info" block active={nav_average===1} onClick={() => {set_nav_average(1)}} >
                    Mean
                    </Button>
                    <Button color="info" block active={nav_average===2} onClick={() => { set_nav_average(2)}} >
                        Max
                    </Button>
                    <Button color="info" block active={nav_average===3} onClick={() => {set_nav_average(3)}} >
                        SDev
                    </Button>
            </ButtonGroup>
        </div>
     

    <Table bordered dark style={{width:"60%", borderWidth:"1px", borderStyle:"solid", borderColor:"#eeeeee", rules:"all"}}>
    <tbody>
    <tr>
        {/* We want to do this as a function, pass it parameter of data, and channel, and mean, min, max etc, and then retur nthe value, or this will be a mess */}
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(4, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(2, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(0, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(10, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(8, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(6, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(5, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(3, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(1, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(11, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(9, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(7, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(16, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(14, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(12, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(22, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(20, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(18, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(17, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(15, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(13, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(23, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(21, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{getTableEntryValue(19, tableData, nav_test_type===0 || nav_test_type===2 ? TableDataType.Distance : TableDataType.Ratio, nav_average)}</td>
        </tr>
    </tbody>
    </Table>

    {/* <h1>{tableData}</h1> */}
    </div>
    </div>
    </div>
    </>
    );
}