import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter,
RouterProvider,
Route, } from 'react-router-dom'
import './index.css';
import "bootstrap/dist/css/bootstrap.min.css";
import App from './App';

import Root from "./routes/root";
import ErrorPage from './routes/error';
import {SensorList, loader as sensorLoader} from './routes/sensor_list'
import {SensorDetail, loader as sensorDetailLoader} from './routes/sensor_detail'
import {CalDetail, loader as calDetailLoader} from './routes/cal_detail'
import {PTDetail, loader as ptDetailLoader} from './routes/pt_detail'
import {BLDetail, loader as blDetailLoader} from './routes/bl_detail'
import {DiffDetail, loader as diffDetailLoader} from './routes/diff_detail'
import {CollDetail, loader as collDetailLoader} from './routes/coll_detail'
import {LoginPage, loader as loginLoader} from './routes/login_page'
import {ProductionTestList, blProdLoader, collProdLoader, diffProdLoader, calProdLoader, ptProdLoader} from './routes/prod_list'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "sensors",
        element: <SensorList />,
        loader: sensorLoader,
      },
      {
        path: "sensors/:sensorid",
        element: <SensorDetail />,
        loader: sensorDetailLoader,
      },
      {
        path: "cal/:calid",
        element: <CalDetail />,
        loader: calDetailLoader,
      },
      {
        path: "pt/:ptid",
        element: <PTDetail />,
        loader: ptDetailLoader,
      },
      {
        path: "bl/:blid",
        element: <BLDetail />,
        loader: blDetailLoader,
      },
      {
        path: "diff/:diffid",
        element: <DiffDetail />,
        loader: diffDetailLoader,
      },
      {
        path: "coll/:collid",
        element: <CollDetail />,
        loader: collDetailLoader,
      },
      /* PROD LISTS */

      {
        path: "bl",
        element: <ProductionTestList />,
        loader: blProdLoader,
      },
      {
        path: "coll",
        element: <ProductionTestList />,
        loader: collProdLoader,
      },
      {
        path: "diff",
        element: <ProductionTestList />,
        loader: diffProdLoader,
      },
      {
        path: "cal",
        element: <ProductionTestList />,
        loader: calProdLoader,
      },
      {
        path: "pt",
        element: <ProductionTestList />,
        loader: ptProdLoader,
      },
      {
        path: "login",
        element: <LoginPage />,
        loader: loginLoader,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);


// render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>,
//   document.querySelector('#root')
//)

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
