import { Form, useLoaderData, Link, useLocation, Outlet, NavLink as RRNavLink, useParams, useNavigate } from "react-router-dom";
import { Table, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink,     Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Button, ButtonGroup,
  } from "reactstrap";
  import {useRef, useState} from 'react';
import axios from "axios";



import { BASE_API_URL, BASE_MEDIA_URL, BASE_URL } from "../constants";

function constructAPIURL(serial_number){
    var api_url = BASE_API_URL;
    api_url += "/";
    api_url += serial_number;
    api_url += "/cal";
    return api_url;
}
function constructSensorAPIURL(serial_number){
    var api_url = BASE_API_URL;
    api_url += "/";
    api_url += serial_number;
    return api_url; 
}

function constructPTAPIURL(serial_number){
  var api_url = BASE_API_URL;
  api_url += "/";
  api_url += serial_number;
  api_url += "/pt";
  return api_url;
}
function constructBLAPIURL(serial_number){
  var api_url = BASE_API_URL;
  api_url += "/";
  api_url += serial_number;
  api_url += "/bl";
  return api_url;
}
function constructDiffuserAPIURL(serial_number){
  var api_url = BASE_API_URL;
  api_url += "/";
  api_url += serial_number;
  api_url += "/diff";
  return api_url;
}

function constructCollimationAPIURL(serial_number){
  var api_url = BASE_API_URL;
  api_url += "/";
  api_url += serial_number;
  api_url += "/coll";
  return api_url;
}

function constructAPICountURL(){
    var api_url = BASE_API_URL;
    api_url += "?count=y";
    return api_url;
}

function gen_nav_link(navbar){
  switch(navbar){
    case 0:
      return 'bl';
    case 1:
      return 'cal';
    case 2: 
      return 'pt';
  }
  return 'err';
}

function gen_nav_link_2(navbar){
  switch(navbar){
    case 0:
      return 'coll';
      case 1:
        return 'diff';
  }
  return 'err';
}

  export async function getSensors(request, serial_number){
    const url = new URL(request.url);

    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(serial_number);
    const api_url_sensor = constructSensorAPIURL(serial_number);
    const api_url_pantilt = constructPTAPIURL(serial_number);
    const api_url_bootloader = constructBLAPIURL(serial_number);
    const api_url_diffuser = constructDiffuserAPIURL(serial_number);
    const api_url_collimation = constructCollimationAPIURL(serial_number);
    console.log("URL");
    console.log(api_url_bootloader);
    const sensor_data = await axios.get(api_url_sensor);
    const cal_data = await axios.get(api_url);
    const pt_data = await axios.get(api_url_pantilt);
    const bl_data = await axios.get(api_url_bootloader);
    const diff_data = await axios.get(api_url_diffuser);
    const coll_data = await axios.get(api_url_collimation);
    console.log("D1");
    console.log(bl_data);
    return { sensor_data, cal_data, pt_data, bl_data, diff_data, coll_data };
  }

  export async function loader({ request, params }){
    const { sensor_data, cal_data, pt_data, bl_data, diff_data, coll_data } = await getSensors(request, params.sensorid);
    console.log("D2");
    console.log(bl_data)
    return { sensor_data, cal_data, pt_data, bl_data, diff_data, coll_data };
  }

export function SensorDetail() {
  const {  sensor_data, cal_data, pt_data, bl_data, diff_data, coll_data } = useLoaderData(); // add pt...
  const navigate = useNavigate()
  console.log("D3");
  console.log(bl_data);
  console.log("D4");
  console.log(cal_data);
  
  var [nav_bar_one, set_nav_one] = useState(1);
  var [nav_bar_two, set_nav_two] = useState(1);
  var [left_link_data, set_left_link] = useState(cal_data)
  var [right_img_data, set_right_data] = useState(diff_data)

  console.log(diff_data)
  return (
    <>
    <div style={{display:"flex", flexDirection:"column"}}>

        <div>
            <h1>SN #{sensor_data.data['serial_number']}</h1><h4><i>Last modified {sensor_data.data['last_modified_date']}</i></h4>
        </div>
        
        <div style={{display:"flex", flexDirection:"row"}}>



    <div className="px-5">
      
    <ButtonGroup block style={{display:"flex", flexDirection:"row"}}>
                    <Button color="info" block active={nav_bar_one === 0} onClick={() => {set_nav_one(0); set_left_link(bl_data); console.log('aaaa')}} >
                        Bootloader
                    </Button>
                    <Button color="info" block active={nav_bar_one === 1} onClick={() => {set_nav_one(1); set_left_link(cal_data); console.log('aaaa')}} >
                        Calibration
                    </Button>
                    <Button color="info" block active={nav_bar_one === 2} onClick={() => {set_nav_one(2); set_left_link(pt_data); console.log('aaaa')}} >
                        Pan Tilt
                    </Button>
            </ButtonGroup>


      {/* <Navbar  style={{backgroundColor:"#222222"}}>
          <Nav fill tabs className="me-auto navbar-expand"  style={{display:"flex", flexDirection:"row"}}>
            <NavItem className="px-3">
              <NavLink tag={RRNavLink} to="/sensors" style={{color:"#888888"}}>Bootloader</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink active style={{color:"#888888"}}>Calibration</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink style={{color:"#888888"}}>Pan Tilt</NavLink>
            </NavItem>
          </Nav>
      </Navbar> */}


    <Table dark hover>
    <thead>
        <tr>
        <th>Date</th>
        <th>Pass/Fail</th>
        {/* <th>Code</th> */}
        </tr>
    </thead>
    <tbody>

    {!left_link_data || left_link_data.data.length <= 0 ? (
        <tr>
            <td colSpan="3" align="center">
            <b>No data available</b>
            </td>
        </tr>
        ) : (
            left_link_data.data.map(res => (
            <tr className="hover:bg-gray-50" style={{cursor:"pointer"}} key={res.id} onClick={()=> navigate(`/${gen_nav_link(nav_bar_one)}/${res.id}`)}>
            <td>{res.date}</td>
            <td>{res.pass_fail ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</td>
            {/* <td>{res.code}</td> */}
            </tr>
        )))
        }

    </tbody>
    </Table>
    </div>



    <div className="px-5">
    <ButtonGroup block style={{display:"flex", flexDirection:"row"}}>
        <Button color="info" block active={nav_bar_two === 0} onClick={() => {set_nav_two(0); set_right_data(coll_data); console.log('aaaa')}} >
            Collimation
        </Button>
        <Button color="info" block active={nav_bar_two === 1} onClick={() => {set_nav_two(1); set_right_data(diff_data); console.log('aaaa')}} >
            Diffuser
        </Button>
      </ButtonGroup>
      <img src={
        (right_img_data.data['0']) ? 
        ((right_img_data.data['0']['photo']) ? 
        BASE_URL+right_img_data.data['0']['photo']:
      "/no_img.png") : "/no_img.png"} 
      
      
      alt="Sensor photo" height="480" width="640"></img>

<Table dark hover>
    <thead>
        <tr>
        <th>Date</th>
        <th>Pass/Fail</th>
        {/* <th>Code</th> */}
        </tr>
    </thead>
    <tbody>

    {!right_img_data || right_img_data.data.length <= 0 ? (
        <tr>
            <td colSpan="3" align="center">
            <b>No data available</b>
            </td>
        </tr>
        ) : (
          right_img_data.data.map(res => (
            <tr className="hover:bg-gray-50" style={{cursor:"pointer"}} key={res.id} onClick={()=> navigate(`/${gen_nav_link_2(nav_bar_two)}/${res.id}`)}>
            <td>{res.date}</td>
            <td>{res.pass_fail ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</td>
            {/* <td>{res.code}</td> */}
            </tr>
        )))
        }

    </tbody>
    </Table>


    </div>

    
    </div>
    </div>
    </>
  );
}