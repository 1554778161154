import {  useLoaderData, Link, useLocation } from "react-router-dom";
import { Table, Label, Dropdown, Button, DropdownMenu,  UncontrolledDropdown, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink, FormGroup } from "reactstrap";
import axios from "axios";
import {useRef, useState} from 'react';
import { filterPopup } from "../components/filter";


import { BASE_URL } from "../constants";

export async function loader({ request, params }){
  const { diff_data } = 0;
  return {  diff_data };
}

export function postLogin(username, password){
  console.log("clickiy");
  console.log(username);
  console.log(password);
  var jsonData = {};
  jsonData['username'] = username;
  jsonData['password'] = password;

  console.log(JSON.stringify(jsonData));
  fetch(BASE_URL+"/records/login", {
    method: 'POST',
    mode: 'cors',
    headers: {
        'Accept': 'application/json',
        'Content-type':'application/json'
    },
    body: JSON.stringify(jsonData)
  });
}

export function LoginPage() {
  const { diff_data } = useLoaderData();
  const [ username, setUsername] = useState("");
  const [ password, setPassword] = useState("");

  return (
    <>
    {/* <div id="login-page">
        <center>
            <h1>LOGIN</h1>
            <div style={{width:"40%"}}><h4> <FormGroup>
          <Label >Username</Label>
          <Input id="usernameForm" placeholder="username" onChange={(e) => {setUsername(e.target.value)}}/>
        </FormGroup>
            <FormGroup>
            <Label for="examplePassword">Password</Label>
            <Input type="password" name="password" id="passwordForm" placeholder="password" onChange={(e) => {setPassword(e.target.value)}}/>
          </FormGroup>
          
        <Button onClick={(e) => {postLogin(username, password)}}>Submit</Button></h4></div>
        </center>
    </div> */}

<form action={BASE_URL+"/records/login"} method="post" target="_blank">
  <label for="fname">First name:</label>
  <input type="text" id="username" name="username"/>
  <label for="lname">Last name:</label>
  <input type="text" id="password" name="password"/>
  <input type="submit" value="Submit"/>
</form></>
  );
}