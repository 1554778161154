import React, { Component } from "react";

class SensorNav extends Component {
    render () {
        return (
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
            
          <div className="text-center">
            <h2>SENSORS | JIGS</h2>
          </div>
                      </nav>


        );
      }
    }
    
    export default SensorNav;