import { Form,  useLoaderData, Link, useLocation, Outlet, NavLink as RRNavLink, useParams, useNavigate } from "react-router-dom";
import {useRef, useState} from 'react';
import { Table, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink,     Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink, Button, ButtonGroup } from "reactstrap";
import axios from "axios";



import { BASE_API_URL } from "../constants";

function constructAPIURL(cal_id){
    var api_url = BASE_API_URL;
    api_url += "/cal/";
    api_url += cal_id;
    return api_url;
}
function constructSensorAPIURL(serial_number){
    var api_url = BASE_API_URL;
    api_url += "/";
    api_url += serial_number;
    return api_url; 
}

function constructAPICountURL(){
    var api_url = BASE_API_URL;
    api_url += "?count=y";
    return api_url;
}

  export async function getSensors(request, cal_id){
    const url = new URL(request.url);

    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(cal_id);
    const cal_data = await axios.get(api_url);
    console.log(cal_data)
    return {  cal_data };
  }

  export async function loader({ request, params }){
    const { cal_data } = await getSensors(request, params.calid);
    console.log(cal_data)
    return {  cal_data };
  }

  function renderChannelTable(jsonData){
    /*
    Renders the 24 channel tables for a given data set

    Should be called when one of the links on the side
    is clicked, then we can destroy and update the table...
    */
   return (
    <>
  
        </>
   );
  }

export function CalDetail() {
  const {   cal_data } = useLoaderData();
  const navigate = useNavigate();
  var cal_factor = JSON.parse(cal_data.data['cal_factor']);
  var cal_factor_test = JSON.parse(cal_data.data['cal_factor_test_recv']);
  var cal_factor_target = JSON.parse(cal_data.data['cal_factor_test_target']);
  var rms = JSON.parse(cal_data.data['rms']);
  var noise = JSON.parse(cal_data.data['noise']);
  var rx_isc = JSON.parse(cal_data.data['rx_isc']);
    var distance = JSON.parse(cal_data.data['distance']);
    var [chan_test, set_chan_test] = useState(0);
    var [tableData, setTableData] = useState(cal_factor)

  return (
    <>
    <div style={{display:"flex", flexDirection:"column"}}>

        <div className="px-3 py-3">
            <h1><Link to={"/sensors/" + cal_data.data['sensor']}>SN #{cal_data.data['sensor']}</Link></h1>
            <h4>{cal_data.data['date']}</h4>
            <h4>Result: {cal_data.data['code']} :: {cal_data.data['pass_fail'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}
             
            </h4>
            <h5>Calibration ID: {cal_data.data.id}</h5>
        </div>
        
    <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{width:"50%"}}>
    <Table striped dark style={{width:"90%"}} className="centered">
    <thead>
        <tr>
        <th>Item</th>
        <th>Value</th>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td>Target FW</td>
        <td>{cal_data.data['target_fw']}</td>
    </tr>
    <tr>
        <td>Temperature (C)</td>
        <td>{cal_data.data['temp_deci_c']/10.0}C</td>
    </tr>
    <tr>
        <td>Laser Operator</td>
        <td>{cal_data.data['laser_operator']}</td>
    </tr>
    <tr>
        <td>Laser Power</td>
        <td>{cal_data.data['laser_power']}</td>
    </tr>
    <tr>
        <td>Laser PWM Max</td>
        <td>{cal_data.data['laser_max_pwm']}</td>
    </tr>
    <tr>
        <td>Laser PWM Min</td>
        <td>{cal_data.data['laser_min_pwm']}</td>
    </tr>
    <tr>
        <td>FWHM Wide</td>
        <td>{cal_data.data['fwhm_wide']}</td>
    </tr>
    <tr>
        <td>Tx ISC</td>
        <td>{cal_data.data['tx_isc']}</td>
    </tr>
    <tr>
        <td>AV0 Distance</td>
        <td>{cal_data.data['av0']}</td>
    </tr>

    </tbody>
    </Table>
    </div>

    <div style={{display:"flex", flexDirection:"row", width:"50%"}}>
        <div style={{width:"20%", display:"flex", flexDirection:"column"}}>
            <h2 style={{textAlign:"center"}}>Channels</h2>
                <ButtonGroup block style={{display:"flex", flexDirection:"column"}}>
                    <Button color="info" block active={chan_test===0} onClick={() => {setTableData(tableData=cal_factor); set_chan_test(0)}} >
                        Cal Factor
                    </Button>
                    <Button color="info" block active={chan_test===1} onClick={() => {setTableData(tableData=cal_factor_test); set_chan_test(1)}} >
                        Cal Factor Test
                    </Button>
                    <Button color="info" block active={chan_test===2} onClick={() => {setTableData(tableData=cal_factor_target); set_chan_test(2)}} >
                        Cal Factor Target
                    </Button>
                    <Button color="info" block active={chan_test===3} onClick={() => {setTableData(tableData=rms); set_chan_test(3)}} >
                        RMS
                    </Button>
                    <Button color="info" block active={chan_test===4} onClick={() => {setTableData(tableData=noise); set_chan_test(4)}} >
                        Noise
                    </Button>
                    <Button color="info" block active={chan_test===5} onClick={() => {setTableData(tableData=rx_isc); set_chan_test(5)}} >
                        Rx ISC
                    </Button>
                    <Button color="info" block active={chan_test===6} onClick={() => {setTableData(tableData=distance); set_chan_test(6)}} >
                        Distance
                    </Button>
            </ButtonGroup>
        </div>
     

    <Table bordered dark style={{width:"60%", borderWidth:"1px", borderStyle:"solid", borderColor:"#eeeeee", rules:"all"}}>
    <tbody>
    <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['4'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['2'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['0'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['10'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['8'] : ''} </td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['6'] : ''} </td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['5'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['3'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['1'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['11'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['9'] : ''} </td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['7'] : ''} </td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['16'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['14'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['12'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['22'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['20'] : ''} </td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['18'] : ''} </td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['17'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['15'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['13'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}> {tableData ? tableData['23'] : ''}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['21'] : ''} </td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{tableData ? tableData['19'] : ''} </td>
        </tr>
    </tbody>
    </Table>

    {/* <h1>{tableData}</h1> */}
    </div>
    </div>
    </div>
    </>
  );
}