import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
  import React from 'react';
  import { Outlet, NavLink as RRNavLink } from "react-router-dom";


export default function Root() {
    return (
      <>
       <div>
      <Navbar style={{backgroundColor:"#333333"}}>
        <NavbarBrand tag={RRNavLink} to="/" > <img
                            src="https://images.squarespace-cdn.com/content/v1/5f1e26cb68df2a40e2e01ba5/32b634b1-0263-4f4e-b340-63d5cf9c04de/Seen+Safety+logo+%281%29.png?format=1500w"
                            width="140"
                            alt="Seen Safety"
                            /></NavbarBrand>
          <Nav className="me-auto navbar-expand"  style={{display:"flex", flexDirection:"row"}}>
            <NavItem className="px-3">
              <NavLink tag={RRNavLink} to="/sensors" style={{color:"#EEEEEE"}}>Sensor List</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink tag={RRNavLink} to="/bl" style={{color:"#EEEEEE"}}>Bootloader</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink tag={RRNavLink} to="/coll"   style={{color:"#EEEEEE"}}>Collimation</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink  tag={RRNavLink} to="/diff"  style={{color:"#EEEEEE"}}>Diffuser</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink  tag={RRNavLink} to="/cal"  style={{color:"#EEEEEE"}}>Calibration</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink  tag={RRNavLink} to="/pt" style={{color:"#EEEEEE"}}>Pan Tilt</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink  style={{color:"#EEEEEE"}}>Golden Units</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink  tag={RRNavLink} to="/login" style={{color:"#EEEEEE"}}>LOGIN</NavLink>
            </NavItem>
          </Nav>
      </Navbar>
    </div>
    <div id="content">
        <Outlet />
    </div>
    </>
    );
  }