import { Form, useLoaderData, Link, useLocation } from "react-router-dom";
import { Table, Dropdown, Button, DropdownMenu, Input, UncontrolledDropdown, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink, FormGroup } from "reactstrap";
import axios from "axios";
import {useRef, useState} from 'react';
import { filterPopup } from "../components/filter";

/**
 * Implements a generic view for a list
 * for a production test,
 * just call the different loader for
 * each one
 */

import { BASE_API_URL } from "../constants";

function constructAPIURL(searchParams, stepURL){
    var api_url = BASE_API_URL;
    api_url += stepURL;
    var count = 0;
    searchParams.forEach((value, key) => {
        if(count===0){
            api_url += "?";
        }
        else{
            api_url += "&";
        }
        count++;
        api_url += key + "=" + value;
    });
    var api_count_url = api_url + (count===0 ? "?" : "&") + "count=y";
    return {api_url, api_count_url};
}


/**
 * Get a search params string from a 
 * given dictionary
 * @param {dict} searchParamsDict 
 * @param {boolean} stripSort
 * @param {boolean} stripPage
 */
function getParams(searchParamsDict, stripSort, stripPage, sortOnly){
    var ret_str = "";
    var param_count = 0;
    console.log(searchParamsDict);
    searchParamsDict.forEach((value, key) => {
        if(sortOnly){
            if(key==="sort_by"){
                ret_str += "?" + key + "=" + value;
            }
        }
        else{
            if(!stripPage || ! (key==="limit" || key==="offset")){
                if(!stripSort || !key==="sort_by"){
                    if(param_count===0){
                        ret_str += "?";
                    }
                    else{
                        ret_str += "&";
                    }
                    param_count++;
                    ret_str += key + "=" + value;
                }
            }
        }
    });
    if(!ret_str.includes("?")){
        ret_str+="?"; 
    }
    return ret_str;
}

/**
 * Adds the new filter to a given inputUrl,
 * 
 * @param {string} inputUrl 
 * @param {array} appliedFilters
 * @param {string} newFilter 
 */
function createFilterUrl(inputUrl, appliedFilters, newFilter){
    var str = inputUrl;
    if(str.charAt(str.length-1) != '?'){
        str += "&";
    }
    str += "filter=";
    str += newFilter;
    appliedFilters.forEach(e => str+="+" + e);
    return str;
}

function filterEncode(inputString){
    var res = inputString;
    res = res.replace(/</g, "%3C");
    res = res.replace(/>/g, "%3E");
    res = res.replace(/=/g, "%3D");
    res = res.replace(/!/g, "%21");
    res = res.replace(/,/g, "%2C");
    return res;
}

function getFilters(searchParamsDict){
    var ret_list = [];
    searchParamsDict.forEach((value, key) => {
        if(key==="filter"){
            // Split filters on +
            // But we can copy entire filter within
            var spl = value.split('+'); 
            ret_list.push(...spl);
        }
    });
    ret_list.forEach((item, index, array) => {
        array[index] = filterEncode(item);
    });
    return ret_list;
}

  export async function getData(request, prodStepPath){
    const url = new URL(request.url);
    const searchTerm = url.searchParams;
    const {api_url, api_count_url} = constructAPIURL(searchTerm, prodStepPath);
    const count =  await axios.get(api_count_url);
    const data = await axios.get(api_url);
    return { count, data };
  }

  function getPagination(url, count){
    var pagination = {};    
    const searchTerm = url.searchParams;
    const limit = searchTerm.get('limit');
    const offset = searchTerm.get('offset');
    pagination['count'] = count.data['count'];
    pagination['limit'] = limit;
    pagination['offset'] = offset;
    return pagination
  }

  function getUrls(url){
    var baseUrl = url.origin + url.pathname;
    var baseUrlNoSort = url.origin + url.pathname;
    var baseUrlOnlySort = url.origin + url.pathname;
    baseUrl += getParams(url.searchParams, false, true, false);
    baseUrlNoSort += getParams(url.searchParams, true, true, false);
    baseUrlOnlySort += getParams(url.searchParams, false, false, true);

    var urls = {}
    urls['noPagination'] = baseUrl;
    urls['noPaginationOrSort'] = baseUrlNoSort;
    urls['baseForStep'] = url.origin+url.pathname;
    urls['baseForSensor'] = url.origin+"/sensors";
    urls['baseUrlOnlySort'] = baseUrlOnlySort;
    return urls;
  }

  export async function blProdLoader({ request }){
    const { count, data } = await getData(request, "/bl");

    const url = new URL(request.url);
    var appliedFilters = getFilters(url.searchParams);
    var pagination = getPagination(url, count);
    var urls = getUrls(url);

    var title = 'Bootloader';
    var availableFilters = ['sensor', 'pass_fail', 'date',
                            'ip_high_200', 'ip_low_200', 'ip_low_500',
                            'gainmode_2', 'gainmode_0',
                            'led_5v', 'led_oc1_hiz', 'led_oc2_hiz',
                            'led_oc1_loz', 'led_oc2_loz', 'hw_ver']
    return { title, data, pagination, urls, availableFilters, appliedFilters };
  }

  export async function collProdLoader({ request }){
    const { count, data } = await getData(request, "/coll");

    const url = new URL(request.url);
    var appliedFilters = getFilters(url.searchParams);
    var pagination = getPagination(url, count);
    var urls = getUrls(url);

    var title = 'Collimation';
    var availableFilters = ['sensor', 'pass_fail', 'date' ]
    return { title, data, pagination, urls, availableFilters, appliedFilters };
  }

  export async function diffProdLoader({ request }){
    const { count, data } = await getData(request, "/diff");

    const url = new URL(request.url);
    var appliedFilters = getFilters(url.searchParams);
    var pagination = getPagination(url, count);
    var urls = getUrls(url);

    var title = 'Diffuser';
    var availableFilters = ['sensor', 'pass_fail', 'date' ]
    return { title, data, pagination, urls, availableFilters, appliedFilters };
  }

  export async function calProdLoader({ request }){
    const { count, data } = await getData(request, "/cal");

    const url = new URL(request.url);
    var appliedFilters = getFilters(url.searchParams);
    var pagination = getPagination(url, count);
    var urls = getUrls(url);

    var title = 'Calibration';
    var availableFilters = ['sensor', 'pass_fail', 'date',
                            'target_fw', 'temp_deci_c', 'temp_result',
                            'laser_operator', 'laser_power',
                            'laser_max_pwm', 'laser_min_pwm', 'fwhm_wide',
                            'tx_isc', 'av0', 'code']
    return { title, data, pagination, urls, availableFilters, appliedFilters };
  }

  export async function ptProdLoader({ request }){
    const { count, data } = await getData(request, "/pt");

    const url = new URL(request.url);
    var appliedFilters = getFilters(url.searchParams);
    var pagination = getPagination(url, count);
    var urls = getUrls(url);

    var title = 'Pan Tilt';
    var availableFilters = ['sensor', 'pass_fail', 'date',
                            'cs_pass', 'ss_pass', 'ft_pass']
    return { title, data, pagination, urls, availableFilters, appliedFilters };
  }

export function ProductionTestList() {
  const { title, data, pagination, urls, availableFilters, appliedFilters } = useLoaderData();

  const filterComparisons = [ ">", ">=", "=", "<=", "<", "!="];
  console.log(title);
  console.log(data);
  console.log(pagination);
  console.log(urls);
  console.log(availableFilters);
  console.log(appliedFilters);

  var [filterIndex, setFilterIndex] = useState(0);
  var [filterComparison, setFilterComparison] = useState(0);
  var [filterValue, setFilterValue] = useState(0);

  const prod_items = data.data;

    /* PAGINATION CALCULATIONS */
    var pageLimit = pagination['limit'] ? pagination['limit'] : 100;
    var start, end;
    if(pagination['offset']!=null){
        start = parseInt(pagination['offset']);
    }
    else{
        start = 0;
    }
    if(pagination['limit']!=null){
        end = parseInt(pagination['limit']) + parseInt(pagination['offset']);
        if(end > parseInt(pagination['count'])) { end = parseInt(pagination['count']); }
    }
    else{
        end = pageLimit;
    }
    var page = 1 + parseInt(start / parseInt(pageLimit));
    var pagelist = []
    for(let i=1; i <= 1+(pagination['count']/pageLimit); i++){
        pagelist.push(i);
    }
  
  return (
    <>
    <div className="py-3" style={{display:"flex", flexDirection:"row", color:"#FFFFFF", backgroundColor:"#222222"}}>
        <h2 className="px-2">{title} List</h2>
        {/* <div style={{display:"flex", flexDirection:"row"}}> */}
            <h4 className="px-5">Displaying <i>{ start }</i> to <i>{ end }</i> of <i>{ pagination['count'] }</i></h4>
            <div className="px-5">
            <Pagination size="me">
                <PaginationItem>
                    <PaginationLink first href={urls['noPagination'] + "&limit=" + pageLimit + "&offset=0" } />
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink previous href={urls['noPagination']  + "&limit=" + pageLimit + "&offset=" + (page-2)*pageLimit}/>
                </PaginationItem>
               
                <PaginationItem>
                    <UncontrolledDropdown>
                        <DropdownToggle caret>
                            { page }
                        </DropdownToggle>
                        <DropdownMenu style={{height: 300, overflow: "auto" }}>
                                {pagelist.map(item => (
                                <DropdownItem key={item} href={urls['noPagination']  + "&limit=" + pageLimit + "&offset=" + (item-1)*pageLimit}>{item}</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </PaginationItem>

                <PaginationItem>
                    <PaginationLink next href={urls['noPagination']  + "&limit=" + pageLimit + "&offset=" + (page)*pageLimit}/>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink last href={urls['noPagination']  + "&limit=" + pageLimit + "&offset=" + pageLimit * (parseInt(parseInt(pagination['count']) / pageLimit)) }  />
                </PaginationItem>
            </Pagination>
            </div>

        <div className="px-3" style={{display:"flex", flexDirection:"column", color:"#FFFFFF", backgroundColor:"#222222"}}>
            <div className="px-3" style={{display:"flex", flexDirection:"row", color:"#FFFFFF", backgroundColor:"#222222"}}>
                <h4 className="px-2">Filter where </h4>
                <UncontrolledDropdown>
                    <DropdownToggle caret>
                        {availableFilters[filterIndex]} 
                    </DropdownToggle>
                    <DropdownMenu style={{height: 300, overflow: "auto" }}>
                            {availableFilters.map(item => (
                            <DropdownItem key={item} onClick={ () => setFilterIndex(availableFilters.indexOf(item))}>{item}</DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <h4 className="px-2">is</h4>
                <UncontrolledDropdown>
                    <DropdownToggle caret>
                        {filterComparisons[filterComparison]} 
                    </DropdownToggle>
                    <DropdownMenu style={{overflow: "auto" }}>
                            {filterComparisons.map(item => (
                            <DropdownItem key={item} onClick={ () => setFilterComparison(filterComparisons.indexOf(item))}>{item}</DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <Form className="px-2"><FormGroup> <Input className="px-2" onChange={(e) => {setFilterValue(e.target.value)}}/></FormGroup></Form>
                <Button href={createFilterUrl(urls['baseUrlOnlySort'], appliedFilters, 
                filterEncode(availableFilters[filterIndex] + "," +
                            filterComparisons[filterComparison] + "," +
                            filterValue))}>
                    Add Filter</Button>
            </div>
            <div className="px-3" style={{display:"flex", flexDirection:"row", color:"#FFFFFF", backgroundColor:"#222222"}}>
                Filter list and removal goes here...
            </div>
        </div>
        {/* </div> */}
    </div>
    <Table dark striped>
    <thead>
        <tr>
        <th>ID Number <a href={urls["noPaginationOrSort"] + "sort_by=-id"}>↓</a> <a href={urls["noPaginationOrSort"] + "sort_by=id"}>↑</a></th>
        <th>Sensor SN #<a href={urls["noPaginationOrSort"] + "sort_by=-sensor"}>↓</a> <a href={urls["noPaginationOrSort"] + "sort_by=sensor"}>↑</a></th>
        <th>Date <a href={urls["noPaginationOrSort"] + "sort_by=-date"}>↓</a> <a href={urls["noPaginationOrSort"] + "sort_by=date"}>↑</a></th>
        <th>Pass Fail</th>
        </tr>
    </thead>
    <tbody>
        {!prod_items || prod_items.length <= 0 ? (
        <tr>
            <td colSpan="4" align="center">
            <b>No results available</b>
            </td>
        </tr>
        ) : (
        prod_items.map(prod_item => (
            <tr key={prod_item.id}>
            <td><a href={urls['baseForStep']  + "/" + prod_item.id}>{prod_item.id}</a></td>
            <td><a href={urls['baseForSensor']  + "/" + prod_item.sensor}>{prod_item.sensor}</a></td>
            <td>{prod_item.date}</td>
            <td>{
                    prod_item.pass_fail ? 
                    <span className="badge text-bg-success">PASS</span> : 
                    <span className="badge text-bg-danger">FAIL</span>}</td>
            </tr>
        ))
        )}
    </tbody>
    </Table>
    </>
  );
}