import { Form,  useLoaderData, Link, useLocation, Outlet, NavLink as RRNavLink, useParams, useNavigate } from "react-router-dom";
import {useRef, useState} from 'react';
import { Table, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink,     Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink, Button, ButtonGroup } from "reactstrap";
import axios from "axios";



import { BASE_API_URL } from "../constants";

function constructAPIURL(bl_id){
    var api_url = BASE_API_URL;
    api_url += "/bl/";
    api_url += bl_id;
    return api_url;
}

const ValueType = {
    PreampBias: 0,
    Timing: 1
}

/**
 * Pass in a json string and
 * generates html table
 * @param {json string} dataString 
 */
function generatePreampTable(dataString){
    if(typeof dataString === 'string'){
        var dataJson = JSON.parse(dataString);
    }
    else{
        var dataJson = (dataString);
    }
    return (
        <> 
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['4']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['2']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['0']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['10']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['8']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['6']}</td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['5']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['3']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['1']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['11']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['9']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['7']}</td>
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['16']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['14']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['12']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['22']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['20']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['18']}</td>  
        </tr>
        <tr>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['17']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['15']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['13']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['23']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['21']}</td>
            <td style={{verticalAlign:"middle", textAlign:"center"}}>{dataJson['19']}</td>
        </tr>
        </>
    )
}

/**
 * Create the table rows
 * We want to pass it the data (entire bl data))
 * and then an enum as to whether its a channel map,
 * or a timing thing, note that timing thing is a 2d array
 * @returns 
 */
function generateTableRows(data, valueType, timingStep){
    if(valueType === ValueType.PreampBias){
        return generatePreampTable(data['preamp_bias']);
    }

    if(typeof data['timing_measurement_average'] === 'string'){
        var arr_av = JSON.parse(data['timing_measurement_average']);
    }
    else{
        var arr_av = (data['timing_measurement_average']);
    }    
    if(typeof data['timing_measurement_average'] === 'string'){
        var arr_err = JSON.parse(data['timing_measurement_error']);
    }
    else{
        var arr_err = (data['timing_measurement_error']);
    }   
    if(typeof data['timing_measurement_average'] === 'string'){
        var arr_pwm = JSON.parse(data['timing_measurement_pwm']);
    }
    else{
        var arr_pwm = (data['timing_measurement_pwm']);
    }
    return (
    <>
    <tr>
        <td style={{verticalAlign:"middle", textAlign:"center"}}><b>Average</b></td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_av[timingStep][0]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_av[timingStep][1]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_av[timingStep][2]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_av[timingStep][3]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_av[timingStep][4]}</td>
     </tr>
    <tr>
        <td style={{verticalAlign:"middle", textAlign:"center"}}><b>Error</b></td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_err[timingStep][0]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_err[timingStep][1]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_err[timingStep][2]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_err[timingStep][3]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_err[timingStep][4]}</td>
     </tr>
    <tr>
        <td style={{verticalAlign:"middle", textAlign:"center"}}><b>PWM Value</b></td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>--</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_pwm[timingStep][0]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_pwm[timingStep][1]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_pwm[timingStep][2]}</td>
        <td style={{verticalAlign:"middle", textAlign:"center"}}>{arr_pwm[timingStep][3]}</td>
    </tr>
    </>
    )
}
/*
Creates a series of buttons 0,1,2,3,... for the timing
measurements for a given timing measurement data 

*/
function createButtonGroupTimingMeasurements(visible, dataArray, activeButton, setDataFn){
    if(!visible || dataArray === undefined){
        return ( <> </> )
    }

    var active_index = activeButton !== undefined ? activeButton : 0;
    if(typeof dataArray === 'string'){
        var arr = JSON.parse(dataArray);
    }
    else{
        var arr = (dataArray);
    }
    
    return (
    <>
        <h5 style={{textAlign:"center"}}>Timing Step</h5>
        <ButtonGroup block style={{display:"flex", flexDirection:"row"}}>
            {Object.entries(arr).map(([key,value]) =>
            (
                <Button color="info" key={key} active={key===active_index} block onClick={() => setDataFn(key)} >
                    {key}
                </Button>)
            )}
        </ButtonGroup>   
    </>     
    )    

}

  export async function getSensors(request, bl_id){
    const url = new URL(request.url);

    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(bl_id);
    const bl_data = await axios.get(api_url);
    return {  bl_data };
  }

  export async function loader({ request, params }){
    const { bl_data } = await getSensors(request, params.blid);
    return {  bl_data };
  }


export function BLDetail() {
    const { bl_data } = useLoaderData();
    const navigate = useNavigate();

    console.log(bl_data);

    var [nav_grid_type, set_nav_grid_type] = useState(0);

    var [timing_step, set_timing_step] = useState("0");

    return(
        <>
        <div style={{display:"flex", flexDirection:"column"}}>

        <div className="px-3 py-3">
            <h1><Link to={"/sensors/"+bl_data.data['sensor']}>SN #{bl_data.data['sensor']}</Link></h1>
            <h4>{bl_data.data['date']}</h4>
            { <h4>
                Result :: {bl_data.data['pass_fail'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</h4> }
             
            
            <h5>Bootloader ID: {bl_data.data.id}</h5>
        </div>
        
    <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{width:"50%"}}>
    <Table striped dark style={{width:"90%"}} className="centered">
    <thead>
        <tr>
        <th>Item</th>
        <th>Value</th>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td>Hardware Version</td>
        <td>{bl_data.data['hw_ver']}</td>
    </tr>
    <tr>
        <td>14V8 Voltage (i/p high) 200ms delay</td>
        <td>{bl_data.data['ip_high_200']}</td>
    </tr>
    <tr>
        <td>14V8 Voltage (i/p low) 200ms delay</td>
        <td>{bl_data.data['ip_low_200']}</td>
    </tr>
    <tr>
        <td>14V8 Voltage (i/p low) 500ms delay</td>
        <td>{bl_data.data['ip_low_500']}</td>
    </tr>
    <tr>
        <td>Laser Voltage Gainmode 2</td>
        <td>{bl_data.data['gainmode_2']}</td>
    </tr>
    <tr>
        <td>Laser Voltage Gainmode 0</td>
        <td>{bl_data.data['gainmode_0']}</td>
    </tr>
    <tr>
        <td>LED 5V</td>
        <td>{bl_data.data['led_5v']}</td>
    </tr>
    <tr>
        <td>LED OC1 HiZ Voltage</td>
        <td>{bl_data.data['led_oc1_hiz']}</td>
    </tr>
    <tr>
        <td>LED OC2 HiZ Voltage</td>
        <td>{bl_data.data['led_oc2_hiz']}</td>
    </tr>
    <tr>
        <td>LED OC1 LowZ Voltage</td>
        <td>{bl_data.data['led_oc1_loz']}</td>
    </tr>
    <tr>
        <td>LED OC2 LowZ Voltage</td>
        <td>{bl_data.data['led_oc2_loz']}</td>
    </tr>

    </tbody>
    </Table>
    </div>

    <div style={{display:"flex", flexDirection:"row", width:"50%"}}>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
            
        <div style={{width:"80%", display:"flex", flexDirection:"column"}}>
                <ButtonGroup block style={{display:"flex", flexDirection:"row"}}>
                    <Button color="info" block active={nav_grid_type===0} onClick={() => {set_nav_grid_type(0)}} >
                        Preamp Bias Voltage
                    </Button>
                    <Button color="info" block active={nav_grid_type===1} onClick={() => {set_nav_grid_type(1)}} >
                        Timing Measurements
                    </Button>
            </ButtonGroup>            
         
        </div>

    <Table bordered dark style={{width:"80%", borderWidth:"1px", borderStyle:"solid", borderColor:"#eeeeee", rules:"all"}}>
    <tbody>
        { generateTableRows(bl_data.data, nav_grid_type, timing_step)}
   
    </tbody>
    </Table>

    <div style={{width:"80%", display:"flex", flexDirection:"column"}}>
            {createButtonGroupTimingMeasurements(nav_grid_type, bl_data.data['timing_measurement_average'], timing_step, set_timing_step)}
        </div>
     </div>

    {/* <h1>{tableData}</h1> */}
    </div>
    </div>
    </div>
    </>
    );
}