import React, { Component } from "react";
import SensorList from "./Sensor/SensorList";
import axios from "axios";

import { API_URL } from "../constants";

class Body extends Component {
  state = {
    sensors: []
  };

  componentDidMount() {
    this.resetState();
  }

  getSensors = () => {
    axios.get(API_URL).then(res => this.setState({ sensors: res.data}));
  };

  resetState = () => {
    this.getSensors();
  };

  render() {
    return (
            <h1>Hello</h1>
    );
  }
}

export default Body;