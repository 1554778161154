import { Form,  useLoaderData, Link, useLocation, Outlet, NavLink as RRNavLink, useParams, useNavigate } from "react-router-dom";
import {useRef, useState} from 'react';
import { Table, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Pagination, PaginationItem, PaginationLink,     Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink, Button, ButtonGroup } from "reactstrap";
import axios from "axios";



import { BASE_API_URL, BASE_URL } from "../constants";

function constructAPIURL(diff_id){
    var api_url = BASE_API_URL;
    api_url += "/diff/";
    api_url += diff_id;
    return api_url;
}

  export async function getSensors(request, diff_id){
    const url = new URL(request.url);

    const searchTerm = url.searchParams;
    const api_url = constructAPIURL(diff_id);
    const diff_data = await axios.get(api_url);
    return {  diff_data };
  }

  export async function loader({ request, params }){
    const { diff_data } = await getSensors(request, params.diffid);
    return {  diff_data };
  }


export function DiffDetail() {
    const { diff_data } = useLoaderData();
    const navigate = useNavigate();

    console.log(diff_data);

    return(
        <>
        <div style={{display:"flex", flexDirection:"column"}}>

        <div className="px-3 py-3">
            <h1><Link to={"/sensors/" + diff_data.data['sensor']}>SN #{diff_data.data['sensor']}</Link></h1>
            <h4>{diff_data.data['date']}</h4>
            { <h4>
                Result :: {diff_data.data['pass_fail'] ? 
            <span className="badge text-bg-success">PASS</span> : 
            <span className="badge text-bg-danger">FAIL</span>}</h4> }
             
            
            <h5>Diffuser Alignment ID: {diff_data.data.id}</h5>
        </div>
        
    <div style={{display:"flex", flexDirection:"row"}}>
        <div style={{width:"50%"}}>
    <Table striped dark style={{width:"90%"}} className="centered">
    <thead>
        <tr>
        <th>Item</th>
        <th>Value</th>
        </tr>
    </thead>
    <tbody>
    <tr>
        <td>Diffuser Placement Angle</td>
        <td>{diff_data.data['diffuser_angle']}°</td>
    </tr>
    <tr>
        <td>Horizontal Field of View</td>
        <td>{diff_data.data['horizontal_fov']}°</td>
    </tr>
    <tr>
        <td>Vertical Field of View</td>
        <td>{diff_data.data['vertical_fov']}°</td>
    </tr>
    <tr>
        <td>Bottom Left Diagonal FoV</td>
        <td>{diff_data.data['left_down_diagonal']}°</td>
    </tr>
    <tr>
        <td>Bottom Right Diagonal FoV</td>
        <td>{diff_data.data['right_down_diagonal']}°</td>
    </tr>
    <tr>
        <td>Top Left Diagonal FoV</td>
        <td>{diff_data.data['left_up_diagonal']}°</td>
    </tr>
    <tr>
        <td>Top Right Diagonal FoV</td>
        <td>{diff_data.data['right_up_diagonal']}°</td>
    </tr>

    </tbody>
    </Table>
    </div>

    <div style={{display:"flex", flexDirection:"row", width:"50%"}}>
        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
        <div style={{width:"80%", display:"flex", flexDirection:"column"}}>
                       
        <img src={
        (diff_data.data['photo']) ? 
        BASE_URL+diff_data.data['photo']:
      "/no_img.png"} alt="Sensor photo"></img>
         
        </div>

     </div>

    {/* <h1>{tableData}</h1> */}
    </div>
    </div>
    </div>
    </>
    );
}